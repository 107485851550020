import React, {useEffect} from 'react';
import {Authentication} from './components/auth/Authentication';
import {LogOut} from './components/auth/Logout';
import {AuthState} from './model/auth';
import {User} from './model/user';

import './App.css';

function App() {
    const [authState, updateAuthState] = React.useState<AuthState>(AuthState.Unknown);
    const [user, updateUser] = React.useState<User | null>();
    const [quoteOfTheDay, updateQuoteOfTheDay] = React.useState<{quote: string, author: string} | null>();

    useEffect(() => {
        if (!user?.id) {
            return;
        }

        fetch('https://quotes.rest/qod?category=inspire')
            .then(response => response.json())
            .then(result => {
                const quote = result?.contents?.quotes && result?.contents?.quotes[0];
                if (quote) {
                    updateQuoteOfTheDay({
                        quote: quote.quote,
                        author: quote.author
                    });
                }
                console.dir(quote);
            });
    }, [user?.id]);

    return (
        <Authentication authState={authState} updateAuthStateCallback={updateAuthState} updateUserCallback={updateUser}>
            {user && (
                <div>
                    <div className={'LogOut-Container'}>
                        <LogOut updateAuthState={updateAuthState}  />
                    </div>
                    <span>
                        <strong>Hi {user.first} 👋</strong><br />
                        Welcome to the Noisy Zone 🔊
                    </span>
                    <br />
                    <div className={'Link-Groups'}>
                        <div className={'Link-Group'}>
                            <h3>Keep Organized 🗺️️</h3>
                            <ul className={'Useful-Links'}>
                                <li>
                                    <a className={'App-link'} href={'https://calendar.google.com'} target={'_blank'}>Calendar 🗓</a>
                                    <span className={'Link-Description'}>Your calendar and meetings.</span>
                                </li>
                                <li>
                                    <a className={'App-link'} href={'https://gmail.com'} target={'_blank'}>Gmail 💌</a>
                                    <span className={'Link-Description'}>Your @noisykids.com email.</span>
                                </li>
                                <li>
                                    <a className={'App-link'} href={'https://drive.google.com/'} target={'_blank'}>Drive 💽</a>
                                    <span className={'Link-Description'}>File sharing, shared folders.</span>
                                </li>
                            </ul>
                        </div>
                        <div className={'Link-Group'}>
                            <h3>Collaborate 🐝</h3>
                            <ul className={'Useful-Links'}>
                                <li>
                                    <a className={'App-link'}href={'https://noisykids.slack.com/'} target={'_blank'}>Slack 💬</a>
                                    <span className={'Link-Description'}>Chat, collaboration.</span>
                                </li>
                                <li>
                                    <a className={'App-link'} href={'https://noisykids.atlassian.net/jira/your-work'} target={'_blank'}>JIRA ⛑️</a>
                                    <span className={'Link-Description'}>Project management.</span>
                                </li>
                                <li>
                                    <a className={'App-link'} href={'https://meet.google.com'} target={'_blank'}>Meet 👋</a>
                                    <span className={'Link-Description'}>Video calls.</span>
                                </li>
                            </ul>
                        </div>
                        <div className={'Link-Group'}>
                            <h4>Notion Spaces 📚</h4>
                            <ul className={'Useful-Links'}>
                                <li>
                                    <a className={'App-link'} href={'https://www.notion.so/nk-growth/'} target={'_blank'}>Growth Team 🍄</a>
                                    <span className={'Link-Description'}>
                                        Growth Team's documentation base.<br />
                                        salmen+team@noisykids.com 🤫
                                        <input type={'text'} readOnly={true} className={'Invisible-Password'} value={'nzkz@771+1=772'} />
                                    </span>
                                </li>
                                {user.space && (<li>
                                    <a className={'App-link'} href={`https://www.notion.so/${user.space.three}/`} target={'_blank'}>{user.first}'s Space 👨‍🚀</a>
                                    <span className={'Link-Description'}>
                                        Performance, goals, compensation.<br />
                                        {user.space.one} 🤫
                                        <input type={'text'} readOnly={true} className={'Invisible-Password'} value={user.space.two} />
                                    </span>
                                </li>)}
                            </ul>
                        </div>
                        <div className={'Link-Group'}>
                            <h3>Tools 🪓️</h3>
                            <ul className={'Useful-Links'}>
                                <li>
                                    <a className={'App-link'}href={'https://www.canva.com/'} target={'_blank'}>Canva 🖼️</a>
                                    <span className={'Link-Description'}>
                                        Content creation, graphics.<br />
                                        Login using {user.id}@noisykids.com
                                    </span>
                                </li>
                                <li>
                                    <a className={'App-link'}href={'https://account.adobe.com/'} target={'_blank'}>Adobe 🎨</a>
                                    <span className={'Link-Description'}>
                                        Photoshop and friends.<br />
                                        Login: x2serviceslimited@gmail.com 🤫
                                        <input type={'text'} readOnly={true} className={'Invisible-Password'} value={'AghTiSTO@991'} />
                                    </span>
                                </li>
                                <li>
                                    <a className={'App-link'} href={'https://www.expressvpn.com/vpn-software'} target={'_blank'}>Express VPN 🇺🇸</a>
                                    <span className={'Link-Description'}>
                                        Get a US IP address.<br />
                                        Activation code 🤫
                                        <input type={'text'} readOnly={true} className={'Invisible-Password'} value={'EF42JMNEVBLWSZHBXDX7RHK'} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {quoteOfTheDay && (
                        <div className={'Quote-Of-The-Day'}>
                            <div className={'Title'}>Quote of The Day:</div>
                            <div className={'Quote'}>{quoteOfTheDay.quote}</div>
                            <div className={'Author'}>{quoteOfTheDay.author}</div>
                            <div className={'Source'}>Source: https://theysaidso.com/api/</div>
                        </div>
                    )}
                </div>
            )}
        </Authentication>
    );
}

export default App;
