import React from 'react';
import {GoogleLogout} from 'react-google-login';
import {AuthState} from '../../model/auth';

export type LogOutPropsType = {
    updateAuthState: (value: AuthState) => void;
};

export const LogOut = (props: LogOutPropsType) => {
    const {updateAuthState} = props;
    const onLogoutSuccess = () => {
        updateAuthState(AuthState.LoggedOut);
    }

    return (
        <GoogleLogout
            clientId='419316795259-3u0e5gpek9i27u4fo4nila4oi198oot5.apps.googleusercontent.com'
            buttonText='Logout'
            onLogoutSuccess={onLogoutSuccess}
        />
    );
}
