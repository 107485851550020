import React from 'react';
import {GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline, GoogleLogout} from 'react-google-login';
import {AuthState} from '../../model/auth';
import {User, UserSpace} from '../../model/user';

export type AuthenticationPropsType = {
    authState: AuthState;
    updateAuthStateCallback: (value: AuthState) => void;
    updateUserCallback: (value: User | null) => void;
    children?: any;
};

const spaces: {[key: string]: string[]} = {
    salmen: ['c2FsbWVuQG5vaXN5a2lkcy5jb20=', 'eHl6YWJjMTIz', 'eHl6'],
    ranin: ['c2FsbWVuK3BlcmYrcmFuaW5Abm9pc3lraWRzLmNvbQ==', 'RnNaQWlQdUxNdUQyV1BD', 'bmstcGVyZi1yYW5pbg=='],
    nede: ['c2FsbWVuK3BlcmYrbmVkZUBub2lzeWtpZHMuY29t', 'NmthR1Nha0R6UkhZQUx1', 'bmstcGVyZi1uZWRl']
};

export const Authentication = (props: AuthenticationPropsType) => {
    const {updateUserCallback, updateAuthStateCallback, authState} = props;
    const onSuccessResponse = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        if (response.code) { // GoogleLoginResponseOffline
            // Offline - Unknown state - Registering as logged out
            updateAuthStateCallback(AuthState.LoggedOut);
            updateUserCallback(null);
        }

        const loginResponse = response as GoogleLoginResponse;
        const email = loginResponse.profileObj.email;
        const id = email.substring(0, email.lastIndexOf('@'));
        const space: UserSpace | false = spaces[id] && spaces[id].length === 3 && {
            one: atob(spaces[id][0]),
            two: atob(spaces[id][1]),
            three: atob(spaces[id][2])
        }
        if (loginResponse.isSignedIn()) {
            const user: User = {
                id,
                email,
                first: loginResponse.profileObj.givenName,
                last: loginResponse.profileObj.familyName,
                space: space || undefined
            };

            updateAuthStateCallback(AuthState.LoggedIn);
            updateUserCallback(user);
        } else {
            updateAuthStateCallback(AuthState.LoggedOut);
            updateUserCallback(null);
        }
    };

    const onFailureResponse = (_error: any) => {
        updateAuthStateCallback(AuthState.LoggedOut);
        updateUserCallback(null);
    };

    return (
        <div className='App'>
            <header className='App-header'>
                {authState !== AuthState.LoggedIn && (
                    <>
                        <p>
                            Noisy Zone
                        </p>
                        <GoogleLogin
                            clientId='419316795259-3u0e5gpek9i27u4fo4nila4oi198oot5.apps.googleusercontent.com'
                            buttonText={'Login with your Workspace Account'}
                            onSuccess={onSuccessResponse}
                            onFailure={onFailureResponse}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                        />
                    </>
                )}
                {authState === AuthState.LoggedIn && (
                    <>
                        {props?.children}
                    </>
                )}
            </header>
        </div>
    );
}
